
import { defineComponent, reactive } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Button from 'primevue/button'

export default defineComponent({
  emits: ['nextPage', 'prevPage', 'saveChanges'],
  props: {
    editFormOfferData: {
      type: Object
    },
    isEditing: {
      type: Boolean
    }
  },
  components: {
    Button
  },
  setup (props, { emit }) {
    const state: any = reactive({
      publishDate: null,
      endDate: null
    })

    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)

    const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1))

    const minDateValidator = (publishDate : string) => new Date(publishDate) > yesterday

    const maxDateValidator = (endDate : string) => new Date(endDate) < maxDate

    const datesValidator = () => state.endDate > state.publishDate

    const rules = {
      publishDate: { required, minDateValidator, maxDateValidator, datesValidator },
      endDate: { required, minDateValidator, maxDateValidator, datesValidator }
    }
    let v$ = useVuelidate(rules, state)

    const next = async () => {
      v$.value.$touch()
      const isFormCorrect = await v$.value.$validate()
      if (!isFormCorrect) {
        return
      }
      emit('nextPage', { formOfferData: { publishDate: (state.publishDate as Date), endDate: (state.endDate as Date) }, pageIndex: 2 })
    }

    const back = () => { emit('prevPage', { pageIndex: 2 }) }

    const saveChanges = async () => {
      v$.value.$touch()
      const { publishDate, endDate } = v$.value
      emit('saveChanges', { formOfferData: { publishDate: (state.publishDate as Date), endDate: (state.endDate as Date) } })
    }

    if (props.isEditing) {
      if (props.editFormOfferData?.publishDate != null) {
        const publishStartDate = new Date(props.editFormOfferData?.publishDate)
        state.publishDate = publishStartDate
      }
      if (props.editFormOfferData?.endDate != null) {
        const publishFinishDate = new Date(props.editFormOfferData?.endDate)
        state.endDate = publishFinishDate
      }
      v$ = useVuelidate(rules, state)
    }

    return {
      state,
      v$,
      next,
      back,
      saveChanges
    }
  }
})
